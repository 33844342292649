import React from "react"
import ComingSoon from "react-coming-soon"

export default () => (

    <ComingSoon
        image="https://react-coming-soon.maksv.me/default-image.jpeg"
        bgColor="#fff"
        textColor="#000"
        illustration="development"
      />
) 